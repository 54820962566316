<template>
  <v-main>
    <div class="dashboard-core-view">
      <router-view />
    </div>

    <dashboard-core-footer />
  </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    DashboardCoreFooter: () => import("./Footer"),
  },
};
</script>

<style lang="scss">
.dashboard-core-view {
  padding-left: 0;
  padding-right: 0;
  background-color: $light;
}

@media screen and (min-width: 600px) {
  .dashboard-core-view {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media screen and (min-width: 992px) {
  .dashboard-core-view {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 600px) and (max-width: 800px){
  .body-1 {
    padding-top : 8px;
  }
}

@media screen and (min-width: 800px) and (max-width: 900px){
  .body-1 {
    padding-top : 20px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px){
  .body-1 {
    padding-top : 30px;
  }
}

</style>
